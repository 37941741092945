
















































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import OfficeStaffChangeDialog from "@/components/contractmanagement/OfficeStaffChangeDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OutputExcelDialog from "@/components/contractmanagement/OutputExcelDialog.vue";
import InputExcelDialog from "@/components/contractmanagement/InputExcelDialog.vue";
import { Agreement } from "@/model/agreement";
import { DEFAULT_AGREEMENT } from "@/model/agreement/agreement";

import {
  PORTAL_OFFICE_IS_PUBLIC,
  PORTAL_OFFICE_IS_PUBLIC_COLOR,
  PORTAL_OFFICE_APPROVAL_STATUS,
  PORTAL_OFFICE_APPROVAL_STATUS_COLOR,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS,
  PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR,
} from "@/consts/portal";
import { Office, DEFAULT_OFFICE } from "@/model/office";
import { PortalStaff, DEFAULT_PORTAL_STAFF } from "@/model/portal";

@Component({
  components: {
    ChangeContractDialog,
    OfficeInfo,
    AppAuthButton,
    OfficeStaffChangeDialog,
    OutputExcelDialog,
    InputExcelDialog,
  },
})
export default class PortalOfficeDetail extends Mixins(AxiosMixin, UtilMixin) {
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private portalOffice = null;
  private adminPortalStaff: PortalStaff = { ...DEFAULT_PORTAL_STAFF };
  private agreement: Agreement = { ...DEFAULT_AGREEMENT };
  private ibowOffice: Office | undefined = { ...DEFAULT_OFFICE };

  private PORTAL_OFFICE_IS_PUBLIC = PORTAL_OFFICE_IS_PUBLIC;
  private PORTAL_OFFICE_IS_PUBLIC_COLOR = PORTAL_OFFICE_IS_PUBLIC_COLOR;
  private PORTAL_OFFICE_APPROVAL_STATUS = PORTAL_OFFICE_APPROVAL_STATUS;
  private PORTAL_OFFICE_APPROVAL_STATUS_COLOR =
    PORTAL_OFFICE_APPROVAL_STATUS_COLOR;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS;
  private PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR =
    PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR;

  public async created() {
    await this.fetchPortalOffice(Number(this.$route.params.id));
    this.initLoadingDone = true;
  }

  /** ポータル事業所取得 */
  private async fetchPortalOffice(id: number) {
    return new Promise(() => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_office/get",
        { portal_office_id: id },
        (res) => {
          this.portalOffice = res.data.portal_office;
          this.adminPortalStaff = res.data.admin_portal_staffs[0];
          const ibow_agreement_id =
            res.data.portal_office.portal_agreement.ibow_agreement_id;
          const ibow_office_id = res.data.portal_office.ibow_office_id;

          this.fetchAgreement(ibow_agreement_id, ibow_office_id);
        }
      );
    });
  }

  /** 契約情報取得 */
  private async fetchAgreement(id: number, ibow_office_id: number) {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/agreements/get",
        { is_display_confirm: 1, ids: [id] },
        (res) => {
          this.agreement = res.data.agreements[0];
          this.ibowOffice = this.agreement.offices.find(
            (office) => office.id == ibow_office_id
          );
          // console.log(this.ibowOffice);
          this.initLoadingDone = true;
          console.log(this.initLoadingDone);
          resolve(0);
        }
      );
    });
  }

  /** 詳細ボタンクリック時 */
  private goEdit(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/portal/portal-office-ibow/edit/${id}` });
  }

  /** Ibow企業情報詳細ボタンクリック時 */
  private goIbowAgreement(id: number) {
    /** 詳細画面へ遷移 */
    this.$router.push({ path: `/contractmanagement/detail/${id}` });
  }

  //代理ログイン
  private agentLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/portalstaff-agent-login",
      {
        portal_office_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=1&token=" +
            res.data.onetime_token,
          "_blank"
        );
      }
    );
  }

  //管理者ログイン
  private adminLogin(id: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/portallogin/portalstaff-admin-login",
      {
        portal_office_id: id,
      },
      (res) => {
        window.open(
          window.portal_url +
            "/signin?use_type=1&is_admin=true&token=" +
            res.data.onetime_token,
          "_blank"
        );
      }
    );
  }
}
